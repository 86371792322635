.flex {
  display: flex;
}

.list-item {
  display: list-item;
}

.list-style-none {
  list-style: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mt-1 {
 margin-top: 0.25rem;
}

.mb-1 {
 margin-bottom: 0.25rem;
}

.mb-2 {
 margin-bottom: 0.5rem;
}

.mb-4 {
 margin-bottom: 1rem;
}
