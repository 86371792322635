@import "./main";
@import "./general.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error {
  color: $RedColor;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

@font-face {
  font-family: "Creattion";
  src: url("../assets/fonts/Creattion-Demo.otf") format("opentype");
  font-style: oblique;
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "Helvetica";
//   src: url("../assets/fonts/Helvetica-Light.ttf") format("truetype");
//   font-weight: 300; /* Specify the font weight */
//   font-style: oblique; /* Specify the font style, e.g., normal or italic */
// }

// @font-face {
//   font-family: "Helvetica";
//   src: url("../assets/fonts/Helvetica-Bold.TTF") format("truetype");
//   font-weight: 700; /* Specify the font weight */
//   font-style: oblique; /* Specify the font style, e.g., normal or italic */
// }

// @font-face {
//   font-family: "Helvetica";
//   src: url("../assets/fonts/Helvetica.ttf") format("truetype");
//   font-weight: 400; /* Specify the font weight */
//   font-style: oblique; /* Specify the font style, e.g., normal or italic */
// }

//general classes
